'use client'
// Need to route to TXU Error page https://www.txu.com/page-not-found

import React, { useEffect } from 'react'

const NotFound = () => {
  useEffect(() => {
    window.location.href = 'https://www.shop.txu.com/page-not-found'
  }, [])

  return null // Optionally, you can return null or a loading indicator
}

export default NotFound
